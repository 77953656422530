import React, { Component } from 'react'

export class Projects extends Component {
  render() {
    return (
      <div className='projects'>
          <h3>Projects section</h3>
          <div className="article">
              <p><strong>Coming soon...</strong></p>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. 
                Rerum, recusandae doloribus qui molestias similique asperiores incidunt. 
                Obcaecati tenetur consectetur dolorem eius ex, ad, l
                audantium inventore quia odio minus eligendi ipsa?
                </p>
          </div>
      </div>
    )
  }
}

export default Projects